<template>
  <div>
    <!-- Filters -->
    <filter-user
      :countries="countries"
      :check-permission="checkPermission"
      @filter-query="handleFilters"
    />
    <!--/ Filters -->
    <b-card class="mb-0">
      <!-- Start Modal create User -->
      <div>
        <b-row>
          <b-col v-if="checkPermission(['ALLOW_ALL', 'CREATE_USERS'])">
            <div class="d-flex justify-content-end">
              <create-user-modal
                :nacionalities="nacionalities"
                :countries="countries"
                :clubs="clubs"
                :genders="genders"
                :check-permission="checkPermission"
                :club-info="clubInfo"
                :color-primary-btn="colorPrimaryBtn"
                @successfully="getUsers"
              />
            </div>
          </b-col>
        </b-row>
      </div>
      <!-- End Modal create user -->
      <b-overlay
        :show="isLoading"
        opacity="1"
        :class="isLoading ? 'p-2 mb-1 mt-1' : ''"
      >
        <template #overlay>
          <div class="d-flex align-items-center justify-content-center mt-1">
            <b-spinner
              type="border"
              variant="primary"
            />
          </div>
          <div class="d-flex justify-content-center">
            <p class="pt-1">
              {{ $t('loading') }}
            </p>
          </div>
        </template>
        <b-table
          ref="refUserListTable"
          class="position-relative mt-1"
          responsive
          :fields="headerTable"
          :items="users"
          primary-key="id"
          show-empty
          :empty-text="$t('generic.noRecordsFound')"
        >
          <!-- Column: Avatar -->
          <template #cell(avatar)="data">
            <b-avatar
              :src="data.item.avatar_file"
              class="i-shadow-new"
            />
          </template>
          <!-- Column: club_name -->
          <template #cell(club_name)="data">
            <span v-if="data.item.clubs.length > 0">
              <span
                v-for="club in data.item.clubs"
                :key="club.id"
              >
                - {{ club.club_name }}
              </span>
              <!-- {{ data.item.sport_clubs.club_name }} -->
            </span>
            <span v-else> N/A </span>
          </template>
          <!-- Column: Role -->
          <template #cell(rol_name)="data">
            <span v-if="data.item.roles.length > 0">
              <span
                v-for="role in data.item.roles"
                :key="role.id"
              >
                - {{ role.name }}
              </span>
            </span>
            <span v-else> N/A </span>
          </template>
          <!-- Column: block account -->
          <template #cell(blocked)="data">
            <div v-if="checkPermission(['BLOCK_ACCOUNT', 'ALLOW_ALL'])">
              <b-form-checkbox
                v-model="data.item.blocked"
                switch
                :value="1"
                :unchecked-value="0"
                @change="assignBlockStatus(data.item.id, data.item.blocked)"
              />
            </div>
            <div v-else>
              <span v-if="data.item.blocked === 1">
                {{ $t('generic.yes') }}
              </span>
              <span v-else>
                {{ $t('generic.not') }}
              </span>
            </div>
          </template>
          <!-- Column: status -->
          <template #cell(status)="data">
            <div v-if="checkPermission(['CHANGE_STATUS_USERS', 'ALLOW_ALL'])">
              <b-form-checkbox
                v-if="data.item.roles.id != rolUserAuth.id"
                v-model="data.item.status"
                switch
                :value="1"
                :unchecked-value="0"
                :disabled="data.item.blocked == 1"
                @change="updateStatusUser(data.item.id)"
              />
              <b-form-checkbox
                v-else-if="data.item.roles.id != rolUserAuth.id && rolUserAuth.is_admin_club === 1 || checkPermission(['ALLOW_ALL', 'ALLOW_OSTRAIL'])"
                v-model="data.item.status"
                switch
                :value="1"
                :unchecked-value="0"
                :disabled="data.item.blocked == 1"
                @change="updateStatusUser(data.item.id)"
              />
              <div v-else>
                <span v-if="data.item.status === 1">
                  {{ $t('generic.active') }}
                </span>
                <span v-else-if="data.item.status === 0">
                  {{ $t('generic.inactive') }}
                </span>
                <span v-else>
                  {{ $t('generic.removed') }}
                </span>
              </div>
            </div>
            <div v-else>
              <span v-if="data.item.status === 1">
                {{ $t('generic.active') }}
              </span>
              <span v-else-if="data.item.status === 0">
                {{ $t('generic.inactive') }}
              </span>
              <span v-else>
                {{ $t('generic.removed') }}
              </span>
            </div>
          </template>
          <!-- Column: permissions -->
          <template #cell(permissions)="data">
            <span v-if="checkPermission(['VIEW_PERMISSIONS', 'ALLOW_ALL']) && !data.item.roles[0].allow_all">
              <b-button
                v-if="data.item.roles.id != rolUserAuth.id && rolUserAuth.is_admin_club === data.item.roles.is_admin_club"
                variant="primary"
                class="btn-icon rounded-circle"
                :style="colorPrimaryBtn"
                @click="
                  $router.push({
                    name: 'users-permissions',
                    params: { id: data.item.id },
                  })
                "
              >
                <feather-icon icon="LockIcon" />
              </b-button>
              <b-button
                v-else-if="data.item.roles.id != rolUserAuth.id && rolUserAuth.is_admin_club === 1 || checkPermission(['VIEW_PERMISSIONS', 'ALLOW_ALL'])"
                variant="primary"
                class="btn-icon rounded-circle"
                :style="colorPrimaryBtn"
                @click="
                  $router.push({
                    name: 'users-permissions',
                    params: { id: data.item.id },
                  })
                "
              >
                <feather-icon icon="LockIcon" />
              </b-button>
              <span v-else>
                {{ $t('generic.noAccess') }}
              </span>
            </span>
            <span v-else>
              {{ $t('generic.noAccess') }}
            </span>
          </template>
          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <edit-user-modal
              v-if="!data.item.roles[0].allow_all"
              :user-data="data.item"
              :nacionalities="nacionalities"
              :countries="countries"
              :clubs="clubs"
              :genders="genders"
              :check-permission="checkPermission"
              :club-info="clubInfo"
              :color-primary-btn="colorPrimaryBtn"
              @successfully="getUsers"
            />
            <span v-else>
              {{ $t('generic.noAccess') }}
            </span>
          </template>

        </b-table>
      </b-overlay>
      <!-- Paginador -->
      <app-paginator
        :data-list="paginate"
        @pagination-data="changePaginate"
      />
      <!-- End paginador -->
    </b-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import checkPermission from '@/auth/permissions'
import servicesUser from '@/services/userService'
import servicesClubs from '@/services/clubsService'
import servicesCountry from '@/services/countryService'
import {
  nationalities as serviceNationalities,
  genders as serviceGenders,
} from '@/services/catalogsService'
import FilterUser from './FilterUser.vue'
import AppPaginator from '../components/app-paginator/AppPaginator.vue'
import CreateUserModal from './CreateUserModal.vue'
import EditUserModal from './EditUserModal.vue'

export default {
  components: {
    FilterUser,
    AppPaginator,
    CreateUserModal,
    EditUserModal,
  },
  data() {
    return {
      isLoading: false,
      filters: {
        name: null,
        role_name: null,
        club_hash: null,
        country_hash: null,
        state_hash: null,
        status: null,
      },
      filter: {
        idClub: null,
      },
      paginate: {
        from: 1,
        to: 15,
        total: 0,
      },
      countries: [],
      nacionalities: [],
      genders: [],
      clubs: [],
      users: [],
    }
  },
  computed: {
    ...mapGetters({
      rolUserAuth: 'role',
      clubInfo: 'clubInfo',
      colorPrimaryBtn: 'colorPrimaryBtn',
    }),
    headerTable() {
      return [
        {
          key: 'avatar',
          label: this.$t('generic.avatar'),
          sortable: false,
        },
        {
          key: 'full_name',
          label: this.$t('users.userData.name'),
          sortable: true,
        },
        {
          key: 'email',
          label: this.$t('users.userData.email'),
          sortable: true,
        },
        {
          key: 'club_name',
          label: this.$t('corporate.club'),
          sortable: true,
        },
        {
          key: 'rol_name',
          label: this.$t('roles.rol'),
          sortable: true,
        },
        {
          key: 'status',
          label: this.$t('generic.status'),
          sortable: false,
        },
        {
          key: 'blocked',
          label: this.$t('generic.blocked'),
          sortable: false,
        },
        {
          key: 'permissions',
          label: this.$t('routes.managementRoute.permissions'),
          sortable: false,
        },
        {
          key: 'actions',
          label: '',
          sortable: false,
        },
      ]
    },
  },
  watch: {
    'paginate.from': function () {
      this.getUsers()
    },
    'paginate.to': function () {
      this.getUsers()
    },
    'filters.name': function (name) {
      if (name != null || name !== '') {
        this.filters.role_name = this.filters.name
        this.getUsers()
      }
    },
    'filters.club_hash': function (clubHash) {
      if (clubHash != null || clubHash !== undefined) this.getUsers()
    },
    'filters.country_hash': function (countryHash) {
      if (countryHash != null || countryHash !== undefined) this.getUsers()
    },
    'filters.state_hash': function (stateHash) {
      if (stateHash != null || stateHash !== undefined) this.getUsers()
    },
    'filters.status': function (status) {
      if (status != null) this.getUsers()
    },
  },
  mounted() {
    if (this.checkPermission(['VIEW_USERS'])) {
      if (!this.checkPermission(['ALLOW_ALL', 'ALLOW_OSTRAIL'])) {
        this.filters.idClub = this.clubInfo.hash
      }
      this.getUsers()
      this.getCountries()
      this.getNationalities()
      this.getGenders()
      this.getClubs()
    } else {
      this.$router.push({ name: 'not-authorized' })
    }
  },
  methods: {
    checkPermission,
    async getCountries() {
      try {
        const { data } = await servicesCountry.getCountries()
        this.countries = data.data.map(e => ({ id: e.hash, name: e.labelables[0].label }))
      } catch (error) {
        this.responseCatch(error)
      }
    },
    async getNationalities() {
      try {
        const { data } = await serviceNationalities()
        this.nacionalities = data.data.map(e => ({ id: e.hash, name: e.key }))
      } catch (error) {
        this.responseCatch(error)
      }
    },
    async getGenders() {
      try {
        const { data } = await serviceGenders({})
        this.genders = data.data.map(e => ({ id: e.hash, name: e.labelables[0].label }))
      } catch (error) {
        this.responseCatch(error)
      }
    },
    async getClubs() {
      try {
        const filter = { status: 1 }
        const { data } = await servicesClubs.getClubs({}, filter)
        this.clubs = data.data.map(e => ({ id: e.hash, name: e.club_name, active: true }))
      } catch (error) {
        this.responseCatch(error)
      }
    },
    async getUsers() {
      try {
        const params = {
          included: 'address,clubs,roles,jobPositions',
          perPage: this.paginate.to,
          page: this.paginate.from,
        }
        this.isLoading = true
        const { data } = await servicesUser.getUsers(params, this.filters)
        this.paginate.total = data.total
        const list = data.data.map(e => ({
          id: e.hash,
          name: e.name,
          first_name: e.first_name,
          last_name: e.last_name,
          full_name: e.full_name,
          email: e.email,
          cell_phone: e.cell_phone,
          gender_id: e.gender_hash,
          nationality_id: e.nationality_hash,
          address_id: e.address_hash,
          status: (e.status ? 1 : 0),
          blocked: e.status == false && e.counter >= 4 ? 1 : 0,
          clubs: e.clubs,
          roles: e.roles,
          address: {
            id: (e.address ? e.address.hash : null),
            address: (e.address ? e.address.address : null),
            country_id: (e.address ? (e.address.country_hash || null) : null),
            state_id: (e.address ? (e.address.state_hash || null) : null),
            zip_code: (e.address ? e.address.zip_code : null),
          },
          job_positions: e.job_positions,
          is_internal: (e.roles[0].club_hash == 0) ? 1 : 0,
          role_id: e.roles[0].hash,
          assigned: e.clubs.map((c, index) => {
            const roleIndex = e.roles.findIndex(f => c.hash === f.club_hash)
            const jobIndex = e.job_positions.findIndex(g => c.hash === g.club_hash)
            return {
              id: Date.now() + index,
              club_id: c.hash,
              role_id: e.roles[roleIndex].hash,
              job_position_id: e.job_positions[jobIndex].hash,
            }
          }),
        }))
        this.users = list
        this.isLoading = false
      } catch (error) {
        this.isLoading = false
        this.responseCatch(error)
      }
    },
    handleFilters(filterSelect) {
      if (filterSelect) {
        this.filters.name = filterSelect.search
        this.filters.role_name = filterSelect.search
        this.filters.club_hash = filterSelect.idClub
        this.filters.country_hash = filterSelect.idCountry
        this.filters.state_hash = filterSelect.idState
        this.filters.status = filterSelect.status
      }
      this.getUsers()
    },
    changePaginate(paginateData) {
      this.paginate.to = paginateData.perPage
      this.paginate.from = paginateData.page
    },
    async updateStatusUser(user) {
      await servicesUser.statusUser(user).then(({ data }) => {
        this.responseSuccessUpdate(data.message)
        this.getUsers()
      }).catch(error => {
        this.responseCatch(error)
      })
    },
    assignBlockStatus(pId, pValue) {
      const params = { hash: pId, current_value: pValue }

      servicesUser.setBlockStatus(pId, params).then(({ data }) => {
        this.responseSuccessUpdate(data.message)
        this.getUsers()
      }).catch(error => {
        this.responseCatch(error)
      })
    },
    getUsersFilterReset() {
      this.filters.page = 1
      this.filters.perPage = 15
      this.filters.idClub = this.clubInfo.hash || null
      this.getUsers()
    },
  },
}
</script>
